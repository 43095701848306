import { atom, useAtom } from 'jotai';
import { EcommerceConfirmationType } from '@aftersale-next/graphql';
export var ecommerceUrlSettingsAtom = atom({
  url: '',
  utm: {
    utmMainLogo: '',
    utmCoupon: ''
  }
});
export var ecommerceEnabledAtom = atom(false);
export var ecommerceName = atom('');
export var ecommerceImages = atom([]);
export var ecommerceConfirmationType = atom(EcommerceConfirmationType.Email);
export var ecommerceFeatures = atom([]);
export var isFetchingEcommerce = atom(true);
export var useEcommerceUrlSettings = function useEcommerceUrlSettings() {
  return useAtom(ecommerceUrlSettingsAtom);
};
export var useEcommerceEnabled = function useEcommerceEnabled() {
  return useAtom(ecommerceEnabledAtom);
};
export var useEcommerceName = function useEcommerceName() {
  return useAtom(ecommerceName);
};
export var useEcommerceImages = function useEcommerceImages() {
  return useAtom(ecommerceImages);
};
export var useEcommerceConfirmationType = function useEcommerceConfirmationType() {
  return useAtom(ecommerceConfirmationType);
};
export var useEcommerceFeatures = function useEcommerceFeatures() {
  return useAtom(ecommerceFeatures);
};
export var useIsFetchingEcommerce = function useIsFetchingEcommerce() {
  return useAtom(isFetchingEcommerce);
};