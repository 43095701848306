import { useQuery, useMutation } from 'react-query';
import { axiosFetcher } from '../custom-fetcher';
export var ChannelId;

(function (ChannelId) {
  ChannelId["Sms"] = "sms";
  ChannelId["Whatsapp"] = "whatsapp";
  ChannelId["Email"] = "email";
})(ChannelId || (ChannelId = {}));

export var EcommerceConfirmationType;

(function (EcommerceConfirmationType) {
  EcommerceConfirmationType["Email"] = "email";
  EcommerceConfirmationType["Document"] = "document";
})(EcommerceConfirmationType || (EcommerceConfirmationType = {}));

export var SurveyType;

(function (SurveyType) {
  SurveyType["Ces"] = "CES";
})(SurveyType || (SurveyType = {}));

export var EcommerceSettingsDocument = "\n    query ecommerceSettings($host: String!) {\n  settings(host: $host) {\n    id\n    name\n    enabled\n    url\n    confirmation_type\n    demo\n    images {\n      type\n      url\n    }\n    utm {\n      utmMainLogo\n      utmCoupon\n      utmEcommerceDisabled\n    }\n    features\n    email\n  }\n}\n    ";
export var useEcommerceSettingsQuery = function useEcommerceSettingsQuery(variables, options) {
  return useQuery(['ecommerceSettings', variables], axiosFetcher(EcommerceSettingsDocument, variables), options);
};
export var ProductsSimilarityDocument = "\n    query productsSimilarity($host: String!, $orderId: String!) {\n  similarity(host: $host, orderId: $orderId) {\n    id\n    name\n    sku\n    price\n    original_images\n    store_url\n  }\n}\n    ";
export var useProductsSimilarityQuery = function useProductsSimilarityQuery(variables, options) {
  return useQuery(['productsSimilarity', variables], axiosFetcher(ProductsSimilarityDocument, variables), options);
};
export var EcommerceSurveyDocument = "\n    query ecommerceSurvey($journeyId: String!, $host: String!, $order: String!, $confirmation: String!) {\n  survey(\n    journeyId: $journeyId\n    host: $host\n    order: $order\n    confirmation: $confirmation\n  ) {\n    type\n  }\n}\n    ";
export var useEcommerceSurveyQuery = function useEcommerceSurveyQuery(variables, options) {
  return useQuery(['ecommerceSurvey', variables], axiosFetcher(EcommerceSurveyDocument, variables), options);
};
export var GetJourneyDocument = "\n    query getJourney($journeyId: String!) {\n  journey(journeyId: $journeyId) {\n    ecommerceUuid\n    order\n    coupon {\n      name\n      rules\n      expirationDate\n    }\n    customer {\n      document\n      email\n      firstName\n      lastName\n      phone\n    }\n  }\n}\n    ";
export var useGetJourneyQuery = function useGetJourneyQuery(variables, options) {
  return useQuery(['getJourney', variables], axiosFetcher(GetJourneyDocument, variables), options);
};
export var GetTrackingsDocument = "\n    query getTrackings($host: String!, $order: String!, $confirmation: String!) {\n  tracking(host: $host, order: $order, confirmation: $confirmation) {\n    id\n    externalOrderId\n    deliveredAt\n    appWebviewUrl\n    customer {\n      email\n      document\n      phone\n    }\n    isPickupStore\n    isNotificationActive\n    status\n    channels {\n      channel\n      data {\n        email\n        phone\n      }\n      enabled\n    }\n    ecommerceProcess {\n      events {\n        id\n        date\n        code\n        observed\n        description\n        details\n      }\n    }\n    feedbacks {\n      rating\n      commentary\n      type\n    }\n    coupon {\n      name\n      expirationDate\n      rules\n      isActive\n    }\n    packages {\n      id\n      code\n      courier\n      trackingUrl\n      invoiceUrl\n      packageIndex\n      estimatedDeliveryDate\n      newEstimatedDeliveryDate\n      customerDeliveryDate\n      deliveryDate\n      actions\n      logisticProviderName\n      events {\n        id\n        date\n        code\n        description\n        details\n        latitude\n        longitude\n      }\n      occurrence {\n        id\n        totalPackageValue\n      }\n      products {\n        name\n        sku\n        qty\n        price\n        sellingPrice\n        originalImages\n      }\n    }\n    channelList {\n      id\n      name\n      enabled\n      data {\n        phone_number\n        greeting\n      }\n    }\n    troqueFacilRedirectLink\n  }\n}\n    ";
export var useGetTrackingsQuery = function useGetTrackingsQuery(variables, options) {
  return useQuery(['getTrackings', variables], axiosFetcher(GetTrackingsDocument, variables), options);
};
export var GetNewTrackingDocument = "\n    query getNewTracking($host: String!, $order: String!, $confirmation: String!) {\n  newTracking(host: $host, order: $order, confirmation: $confirmation) {\n    id\n    externalOrderId\n    deliveredAt\n    appWebviewUrl\n    customer {\n      email\n      document\n      phone\n    }\n    isPickupStore\n    isNotificationActive\n    status\n    channels {\n      channel\n      data {\n        email\n        phone\n      }\n      enabled\n    }\n    feedbacks {\n      rating\n      commentary\n      type\n    }\n    coupon {\n      name\n      expirationDate\n      rules\n      isActive\n    }\n    packages {\n      id\n      code\n      courier\n      trackingUrl\n      estimatedDeliveryDate\n      newEstimatedDeliveryDate\n      customerDeliveryDate\n      deliveryDate\n      actions\n      events {\n        id\n        date\n        code\n        description\n        details\n        latitude\n        longitude\n      }\n    }\n    channelList {\n      id\n      name\n      enabled\n      data {\n        phone_number\n        greeting\n      }\n    }\n    troqueFacilRedirectLink\n  }\n}\n    ";
export var useGetNewTrackingQuery = function useGetNewTrackingQuery(variables, options) {
  return useQuery(['getNewTracking', variables], axiosFetcher(GetNewTrackingDocument, variables), options);
};
export var DoActionDocument = "\n    mutation doAction($host: String!, $order: String!, $confirmation: String!, $data: ActionInput) {\n  action(host: $host, order: $order, confirmation: $confirmation, data: $data) {\n    events {\n      id\n      date\n      code\n      description\n      details\n    }\n  }\n}\n    ";
export var useDoActionMutation = function useDoActionMutation(options) {
  return useMutation(function (variables) {
    return axiosFetcher(DoActionDocument, variables)();
  }, options);
};
export var SetChannelDocument = "\n    mutation setChannel($host: String!, $order: String!, $confirmation: String!, $data: ChannelInput) {\n  channel(host: $host, order: $order, confirmation: $confirmation, data: $data)\n}\n    ";
export var useSetChannelMutation = function useSetChannelMutation(options) {
  return useMutation(function (variables) {
    return axiosFetcher(SetChannelDocument, variables)();
  }, options);
};
export var DoFeedbackDocument = "\n    mutation doFeedback($host: String!, $order: String!, $confirmation: String!, $data: FeedbackInput) {\n  feedback(host: $host, order: $order, confirmation: $confirmation, data: $data)\n}\n    ";
export var useDoFeedbackMutation = function useDoFeedbackMutation(options) {
  return useMutation(function (variables) {
    return axiosFetcher(DoFeedbackDocument, variables)();
  }, options);
};
export var DoDisableNotificationDocument = "\n    mutation doDisableNotification($host: String!, $order: String!, $confirmation: String!) {\n  disableNotification(host: $host, order: $order, confirmation: $confirmation)\n}\n    ";
export var useDoDisableNotificationMutation = function useDoDisableNotificationMutation(options) {
  return useMutation(function (variables) {
    return axiosFetcher(DoDisableNotificationDocument, variables)();
  }, options);
};
export var DoSurveyDocument = "\n    mutation doSurvey($journeyId: String!, $data: SurveyInput) {\n  sendSurvey(journeyId: $journeyId, data: $data)\n}\n    ";
export var useDoSurveyMutation = function useDoSurveyMutation(options) {
  return useMutation(function (variables) {
    return axiosFetcher(DoSurveyDocument, variables)();
  }, options);
};