import { atom, useAtom } from 'jotai';
var MINUTE = 1000 * 60;
export var trakrServerAtom = atom('');
export var orderAtom = atom('');
export var packageIndexAtom = atom(0);
export var confirmationAtom = atom('');
export var xhostAtom = atom('');
export var embedAtom = atom(false);
export var poolingIntervalAtom = atom(5 * MINUTE);
export var useTrakrServer = function useTrakrServer() {
  return useAtom(trakrServerAtom);
};
export var useOrder = function useOrder() {
  return useAtom(orderAtom);
};
export var usePackageIndex = function usePackageIndex() {
  return useAtom(packageIndexAtom);
};
export var useConfirmation = function useConfirmation() {
  return useAtom(confirmationAtom);
};
export var useXHost = function useXHost() {
  return useAtom(xhostAtom);
};
export var useIsEmbed = function useIsEmbed() {
  return useAtom(embedAtom);
};
export var usePoolingInterval = function usePoolingInterval() {
  return useAtom(poolingIntervalAtom);
};